import RailsUjs from '@rails/ujs';
import LocalTime from "local-time";
import './shared/cloudinary';
import './shared/sentry';
import './shared/set-timezone';
import './shared/set-csrf';

RailsUjs.start();
LocalTime.start();


document.addEventListener('click', function(e) {
  e = e || window.event;
  var target = e.target || e.srcElement;
  if (target.hasAttribute('data-toggle') && target.getAttribute('data-toggle') == 'modal-custom') {
    if (target.hasAttribute('data-target')) {
      var m_ID = target.getAttribute('data-target');
      document.getElementById(m_ID).classList.add('open');
      e.preventDefault();
    }
  }

  // Close modal window with 'data-dismiss' attribute or when the backdrop is clicked
  if ((target.hasAttribute('data-dismiss') && target.getAttribute('data-dismiss') == 'modal-custom') || target.classList.contains('modal-custom')) {
    var modal = document.querySelector('[class="modal-custom open"]');
    modal.classList.remove('open');
    e.preventDefault();
  }
}, false);
let collapse = document.querySelector("#navbar-collapse");

function menuopen() {
  collapse.classList.toggle('hidden');
  collapse.classList.toggle('flex');
}
document.querySelector('#navbar-toggle').addEventListener('click', menuopen);

let bottommenu_content = document.querySelector("#bottomnavbar-collapse");

function menubottompen() {
  bottommenu_content.classList.toggle('hidden');
  bottommenu_content.classList.toggle('block');
}
document.querySelector('#bottomnavbar-toggle').addEventListener('click', menubottompen);

//use window.scrollY
const header = document.getElementById("nav");
const backtotop = document.getElementById("backtotop");

function add_class_on_scroll() {
  header.classList.add("sticky");
}
function showbacktotop() {
  backtotop.classList.add("show");
}
function removebacktotop() {
  backtotop?.classList?.remove("show");
}
function remove_class_on_scroll() {
  header.classList.remove("sticky");
}
const acc = document.getElementsByClassName("accordion-title");
const acc_panel = document.querySelectorAll(".accordion-panel");
let i;
for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.classList.contains('active')) {

      panel.classList.remove('active');
    } else {
      panel.classList.add('active');
    }
  });
}
window.addEventListener('scroll', function() {
  //Here you forgot to update the value
  const scrollpos = window.scrollY;

  if (scrollpos > 38) {
    add_class_on_scroll();
  }
  else {
    remove_class_on_scroll();
  }
  if (scrollpos > 100) {
    showbacktotop();
  }
  else {
    removebacktotop();
  }
});
backtotop.addEventListener('click', function(e) {
  window.scrollTo(0, 0);
});
document.addEventListener('DOMContentLoaded', function() {
  let head = document.getElementsByTagName('html');
  let navbar = document.getElementById('navbar-collapse');
  let topmenu = document.getElementById('top-menu');
  let topcontact = document.getElementById('top-contact');
  let body = document.getElementsByTagName('body')[0];
  const current_locale = document.querySelector('#current_locale')


  if(current_locale.value == "ar"){
    const footer = document.querySelector(".footer")
    const header = document.querySelector(".header")
    if(footer){
      footer.classList.add("footer-arabic-content-ar");
    }
    if(header){
      header.classList.add("header-arabic-content-ar");
      document.querySelector("#top-contact").children[1].innerHTML = '<i class="fas fa-phone"></i>+966-537-116739';
    }
  }
  if (head) {
    let lang = head[0].getAttribute('lang');
    if (lang == 'ar') {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      navbar.classList.remove('md:ml-auto');
      navbar.classList.add('md:mr-auto');
      body.classList.add('ar');
      body.classList.remove('en');
      topmenu.classList.remove('md:text-right');
      topmenu.classList.add('md:text-left');
      topcontact.classList.remove('md:text-left');
      topcontact.classList.add('md:text-right');
      topcontact.setAttribute("dir", "ltr");
    }
    else if (lang == 'ur') {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      navbar.classList.remove('md:ml-auto');
      navbar.classList.add('md:mr-auto');
      body.classList.add('ar', 'ur');
      body.classList.remove('en');
      topmenu.classList.remove('md:text-right');
      topmenu.classList.add('md:text-left');
      topcontact.classList.remove('md:text-left');
      topcontact.classList.add('md:text-right');
      topcontact.setAttribute("dir", "ltr");
    }
    else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      navbar.classList.remove('md:mr-auto');
      navbar.classList.add('md:ml-auto');
      body.classList.add('en');
      body.classList.remove('ar', 'ur');
      topmenu.classList.remove('md:text-left');
      topmenu.classList.add('md:text-right');
      topcontact.classList.remove('md:text-right');
      topcontact.classList.add('md:text-left');
      topcontact.setAttribute("dir", "ltr");
    }
  }
});